.accordion {
    border: 1px solid #ddd;
}

.accordion-item {
    border-bottom: 1px solid #ddd;
}

.accordion-header {
    background-color: #f4f4f4;
    padding: 10px;
    cursor: pointer;
}

.accordion-header.active {
    background-color: #ccc; /* Change background color when active */
}

.arrow {
    float: right;
}

.arrow.open {
    transform: rotate(180deg);
}

.accordion-content {
    padding: 10px;
    display: none;
}

/* Show content when accordion header is clicked */
.accordion-header.active + .accordion-content {
    display: block;
}
