@media print {
    .break-page {
        page-break-after: always;
    }

    @page {
        size: landscape;
    }
}


.id-card-landscape {
    width: 6.732in; /* Sum of front and back side width */
    height: 3.366in; /* Height of the ID card */
}
